* { box-sizing: border-box; }
.video-background {
  background: #000;
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
  z-index: -99;
  background-image: url(/img/explore/explore-video-background.jpg);
}
.video-foreground,
.video-background iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
#vidtop-content {
  top: 0;
  color: #fff;
}
.vid-info { position: absolute; top: 0; right: 0; width: 33%; background: rgba(0,0,0,0.3); color: #fff; padding: 1rem; font-family: Avenir, Helvetica, sans-serif; }
.vid-info h1 { font-size: 2rem; font-weight: 700; margin-top: 0; line-height: 1.2; }
.vid-info a { display: block; color: #fff; text-decoration: none; background: rgba(0,0,0,0.5); transition: .6s background; border-bottom: none; margin: 1rem auto; text-align: center; }
@media (min-aspect-ratio: 16/9) {
  .video-foreground { height: 300%; top: -100%; }
}
@media (max-aspect-ratio: 16/9) {
  .video-foreground { width: 300%; left: -100%; }
}
@media all and (max-width: 600px) {
  .vid-info { width: 50%; padding: .5rem; }
  .vid-info h1 { margin-bottom: .2rem; }
}
@media all and (max-width: 500px) {
  .vid-info .acronym { display: none; }
}

/* HOME DESTINATION SLIDER */

#rev_slider_wrapper3{
  #rev_slider_container{

    .slide-layer-2{
      text-transform: uppercase;
    }
    .slide-layer-3{
      .container-container-custom{
        width: 530px !important;
        margin-left: -15px;
        margin-right: -15px;
      }
    }
    .tp-rightarrow{
      display: none;
    }
    .tp-leftarrow{
      display: none;
    }
    .tp-bullet{
      display: none;
    }
  }
}