.mainContentSection {
  background-image: url("../img/background-4.jpg");
  width: 100%;
  height: auto;
  background-color: $background-color;
  //-webkit-background-size: cover;
  //-moz-background-size: cover;
  //-o-background-size: cover;
  //background-size: cover;
  //background-position: center center;
}
.mainContentMobile {
  background-image: url("../img/background-4.jpg");
  width: 100%;
  height: auto;
  background-color: $background-color;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  padding-top: 10px;
  padding-bottom: 10px;
}
@media (max-width: 460px) {
  .mainContentSection, .whiteSection, .lightSection {
    padding: 50px 0 50px 0;

  }
}
aside .media .media-left {
  width: 40%;
}
aside .media .media-left img {
  width: 75%;
}
.media .media-left, .media>.pull-left {
  padding-right: 0px;
}
.noPad {
  padding: 0;
}
p {
  color: #666;
}
.buttonTransparent {
  padding: 0 20px;
}

/* FAQ */

/* custom Font-Awesome Icons */
//.custom-icon {
//  font-size:45px;
//  background:#859E8C;
//  padding:10px 20px;
//  -webkit-border-radius:1100%;
//  -moz-border-radius:100%;
//  -o-border-radius:100%;
//  border-radius:100%;
//  border:6px solid #fff;
//  color:#fff;
//  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.46);
//  text-align:center;
//  display:table-cell;
//  vertical-align:middle;
//  width:50px;
//  height:50px;
//  -moz-transition:.5s;
//  -webkit-transition:.5s;
//  -o-transition:.5s;
//  transition:.5s;
//  margin:0 auto;
//}
//.custom-icon:hover {
//  background:#859E8C;
//}
.col-mt-10
{
  margin-top: 10px;
}
.col-mb-10 {
   margin-bottom: 10px;
}
.col-ht-10 {
  height: 10px;
}
.m10px {
  margin: 10px;
}
.m25px {
  margin: 25px;
}
.mr20px {
  margin-right:20px;
}
.pt60px {
  padding-top: 60px;
}
.title--underline {
  border-bottom: 1px solid #FFFFFF; }

.title--center {
  margin-bottom: 30px;
  text-align: center; }

.title--decoration-bottom-center {
  padding-bottom: 20px; }

.title--decoration-bottom-center:after {
  content: ' ';
  display: block;
  background: #859E8C;
  position: absolute;
  height: 5px;
  width: 30px;
  bottom: -1px;
  left: 50%;
  transform: translate(-50%, 0); }

.title {
  margin-bottom: 30px;
  position: relative;
}
.section-title {
  margin-bottom: 0 !important;
  padding-top: 20px !important;
}
.sectionTitle h2 span.lightBg.backgroundBg{
  background-color: #EDE1D1;
  text-align: center;
}
//.thumbnail {
//  background-color: #EDE1D1;
//}
.wellBg {
  background-image: url("../img/wells.png");
  background-repeat: repeat;
}
.baranof-ltGreen {
  background-color: $secondary-baranof;
  color: white;
}
.baranof-DkGreen {
  background-color: $primary-baranof;
}
.baranof-tan {
  background-color: #EADAC0;
}
.panel .panel-default {
  border: thin solid #B1A796;
}
.panel .panel-heading {
  background-color: #B1A796;
  border: thin solid #B1A796;
}
//.nav-tabs>li.active>a  {
//   background-color: #B1A796;
//  border-bottom-color: #B1A796;
// }
//.nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
//  background-color: #B1A796;
//  border-bottom-color: #B1A796;
//}
.singlePackage .panel-group .panel-heading {
  background-color: #B1A796;
  color: #555555;
  border-color: #B1A796;
}
.panel-body {
  background-color: #F3E8D8;
}
.fishing-hook {
  background-image: url("../img/fishing-excursions/fishing-hook.png");
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  background-position: right bottom;
}
.halibut-icon {
  background-image: url("../img/fishing-excursions/halibut-fishing-icon.png");
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  background-position: right bottom;
}
#breadcrumbs {
  font-size: small;
}
.breadcrumb {
  padding: 5px 30px;
  margin-bottom: 0;
  background-color: #000;
  color: #ffffff;
  border-radius:0;
}
#breadcrumb ol {
  margin-bottom: 0;
  background-color: #000;
}
.blogSidebar .panel {
  border: none;
  background-color: #958B7A;
}
.nav-tabs>li>a {
  background-color: #F3E8D8;
}
.nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
  background-color: #B1A796 !important;
  border-bottom: none;
  color: #555555 !important;
}
.mb-15px {
  margin-bottom: 15px;
}
.mb-sm {
  margin-bottom: 1rem;
}
.mb-b {
  margin-bottom: 2rem;
}
.mt-15px {
  margin-top: 15px;
}
.mt-30px {
  margin-top: 30px;
}
.mt-40px {
  margin-top: 40px;
}
.ml-25px {
  margin-left: 25px;
}
.ml-mr-25px {
  margin-left: 25px;
  margin-right: 25px;
}
.pt-10px {
  padding-top: 10px;
}
.pt-15px {
  padding-top: 15px;
}
.p-15px {
  padding: 15px;
}
.singlePackage .panel-group {
  margin-bottom: 20px;
}
.fish-species-border {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: #DEDEDE thin solid;
}
hr {
  border-top: thin solid #D6C7AE;
}

.text-white {
  color: #fff;
}

.text-off-white {
  color: #ede1d1;
}
.text-shadow {
  text-shadow: 2px 2px 2px rgba($black-color, 1);
}

.badge-error {
  background-color: #b94a48;
}
.badge-error:hover {
  background-color: #953b39;
}
.badge-warning {
  background-color: #f89406;
}
.badge-warning:hover {
  background-color: #c67605;
}
.badge-success {
  background-color: #859E8C;
}
.badge-success:hover {
  background-color: #01393A;
}
.badge-info {
  background-color: #3a87ad;
}
.badge-info:hover {
  background-color: #2d6987;
}
.badge-inverse {
  background-color: #333333;
}
.badge-inverse:hover {
  background-color: #1a1a1a;
}
.singlePackage .packageInfo .dl-horizontal dd {
  margin-left: 120px;
}
.singlePackage .packageInfo .dl-horizontal dt {
  width: 120px;
}
.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
  background-color: #859E8C;
  border-color: #859E8C;
}
#photoAlbum {
  height: 300px;
  width: 300px;
}
#photoAlbum img {
  height: 300px;
  width: auto;
}

#photoAlbum .cover {
  min-width: 300px;
  width: 100%;
  object-fit: cover;
}
#photo {
  height: 250px;
  width: 250px;
  overflow: hidden;
}
#photo img {
  height: 250px;
  width: auto;
  object-position: 0 0;
}

#photo .cover {
  min-width: 250px;
  width: 100%;
  object-fit: cover;
}
.tt-caps {
  text-transform: capitalize;
}
.tt-lowercase {
  text-transform: lowercase;
}
.text-lft {
  text-align: left;
}
.blogPostItem .lightDrop .sbOptions a{
  text-transform: none;
}
#noHover .isotopeSelector:hover img {
  transform: none;
}
.col-underline {
  padding-bottom: 20px;
  border-bottom: 1px #777777 solid;
}
.col-underline-10 {
  padding-bottom: 10px;
  border-bottom: 1px #777777 solid;
}
.text-ctr {
  text-align: center;
}
.videoBox {
  width: 350px;
  height: 257px;
  display: block;
}
.text-green {
  color: #859E8C;
}
.list-group-item.active, .list-group-item.active:focus, .list-group-item.active:hover {
  background-color: #B1A796;
  border-color: #B1A796;
  color:  #555555;
}
.badge {
  background-color: #555555;
}
.generalInfoTitle h2 {
  font-size: 20px;
  color: #222222;
  line-height: 26px;
  font-weight: 400;
  font-variant-caps: normal;
  margin: 0;

}
#charterPackages .primaryDrop .sbSelector:link, .sbSelector:visited {
  background-color: #B1A796;
}
#charterPackages .primaryDrop  .sbSelector:hover, .sbSelector:focus {
  background-color: #555555;
}
#charterPackages  .primaryDrop .sbOptions a {
  color: #555555 !important;
  text-transform: none;
  text-align: left;
}
#charterPackages  .primaryDrop .sbOptions a:link, .sbOptions a:visited {
  color:#555555; text-decoration:none;
}
#charterPackages  .primaryDrop .sbOptions a:hover {
  color: #01393A !important;
}
#charterPackages .primaryDrop .sbHolder {
  background-color: #555555;
  border: none;
  margin: 0;
  border-radius: 0px;
}
h4 .pc-text, .pc-text:link, .pc-text:visited {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  text-transform: none;
  color: #555555;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 700;
}
h4 .pc-text:hover {
  color: #859E8C;
}
p a:hover {
  color: #555555;
}

@media (max-width:480px) {
  .videoBox {
    width: 250px;
    height: 157px;
  }
  .sectionTitle h2 span {
    padding-left: 1rem;
    padding-right: 1rem;

  }
}

//webcam Carousel captions
.caption-below {
  text-align: center;
}
.caption-below h1 {
  font-size: 1.25em;
  //color: #1a1a1a;
}
.caption-below p {
  margin-left: 20%;
  margin-right: 20%;
 font-size: 1em;
  //color: #1a1a1a;
}
.sectionTitle2 h2 {
  font-size: 26px;
  color: #222;
  line-height: 26px;
  margin: 25px 0;
  font-weight: 400;
}
#boats-for-sale {
  color: black;
  text-align: center;
  background-color: #E9DBC1;
  tr:nth-child(even) {
    background: #F3E8D8;
    padding: 8px;
    text-align: center;
  }
  th {
    padding: 5px 10px;
  }
  td {
    padding: 5px 10px;
  }
}
.masonry-layout {
  column-count: 3;
  column-gap: 0;
}
.masonry-layout__panel {
  break-inside: avoid;
  padding: 5px;
  overflow: hidden;
}
.masonry-layout__panel-content {
  padding: 10px;
  border-radius: 10px;
}
.masonry-layout-panel__content--img {
  max-width: 100%;
  padding: 0;
}
.boat {
  color: black;
  ul {
    list-style-type: none;
  }
  li {
    display: inline-block;
  }
  li:first-child {
    margin-right: 10px;
    font-size: 10px;
    width: 120px;
  }
}

ul.styled {
  li {
    list-style-type: disc;
    padding-left: 20px;
  }
}
ul.modal-list {
  li{
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 10px;
}
  ul {
    li{
      list-style-type: circle;
    }
  }
}


@media (max-width: 460px) {
  .mobileContainer {
    position:relative;
    margin-top: 166px;
  }
  .bannercontainer {
    position:relative;
    margin-top: 82px;
    background-color: #dddddd;
  }
  .ourPackagesSection {
    padding-top: 30px;
    margin-top: -121px;
  }

  .navbar-brand {
    background-image: url(../img/logo-color-big.png);
  }

}