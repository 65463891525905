.whiteLink:link {
  color: #fff;
  text-decoration: none;
}
.whiteLink:visited {
  text-decoration: none;
  color: #fff;
}
.whiteLink:hover {
  text-decoration: underline;
}
.whiteLink:active {
  text-decoration: none;
  color: #fff;
}
.greyLink:link {
  color: #969696;
  text-decoration: none;
}
.greyLink:visited {
  text-decoration: none;
  color: #969696;
}
.greyLink:hover {
  text-decoration: underline;
  color: #859E8C;
}
.greyLink:active {
  text-decoration: none;
  color: #969696;
}
.greenLink:link {
  color: #00393A;
  text-decoration: none;
}
.greenLink:visited {
  text-decoration: none;
  color: #00393A;
}
.greenLink:hover {
  text-decoration: underline;
  color: #859E8C;
}
.greenLink:active {
  text-decoration: none;
  color: #00393A;
}
.darkBrownLink:link {
  color: #7F7777;
  text-decoration: none;
}
.darkBrownLink:visited {
  text-decoration: none;
  color: #7F7777;
}
.darkBrownLink:hover {
  text-decoration: underline;
  color: #969696;
}
.select-Link:link {
  color: #A29696;
  text-transform: uppercase;
  text-decoration: none;
  font-family: "Montserrat",sans-serif;
  font-size: 12px;
  line-height: 42px;
  width: 100%;
  height: 45px;
}
.select-Link:visited {
  text-decoration: none;
  color: #A29696;
  text-transform: uppercase;
}
.select-Link:hover {
  text-decoration: underline;
  color: #969696;
  text-transform: uppercase;
}
.select-Link:active {
  color: #A29696;
  text-transform: uppercase;
  text-decoration: none;
}