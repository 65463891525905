/*================= 6. HOME ==================*/
@import 'header';
@import 'footer';
/*=== 6.1 BANNER ===*/
.static .bannercontainer{
	margin-top:-95px;
}
.fullscreenbanner-container {
	width:100%;
	position:relative;
	padding:0;
	.tp-bullets.preview4{
		opacity: 1 !important;
		height: auto !important;
		@include desktops {
			bottom: $base*6 !important;
		}
	}
	.tp-bullets.preview4 .bullet {
	    background: rgba(255, 255, 255, 0.5) !important;
	    @include border-radius (50%);
	    box-shadow: none!important;
	    width: 6px !important;
	    height: 6px !important;
	    border: 2px solid rgba(255, 255, 255, 0.0) !important;
	    display: inline-block;
	    margin-right: 14px !important;
	    margin-left: 0 !important;
	    margin-bottom: 0px !important;
	    @include transition (background-color 0.2s, border-color 0.2s);
	    float: none !important;
	}
	.tp-bullets.preview4 .bullet:hover, .tp-bullets.preview4 .bullet.selected {
	    box-shadow: none!important;
	    background: rgba(255, 255, 255, 0.0) !important;
	    width: 14px !important;
	    height: 14px !important;
	    border: 2px solid rgba(255, 255, 255, 1) !important;
	    margin-right: 10px !important;
	    margin-left: -4px!important;
	    margin-bottom: -3px!important;
	}
	.tparrows.preview4 .tp-arr-iwrapper {
		display: none !important;
	}
}
.tp-bullets{
	z-index: 99;
}
@include tablet {
	.tparrows.preview4:hover:after{	
		-webkit-transform: rotateY(0);
	 	transform:rotateY(0);
	 }
	.tp-leftarrow.preview4:hover:after{	
		-webkit-transform: rotateY(0); 
		transform:rotateY(0)
	}
}
.fullscreenbanner{
	color: $white-color;
	width:100%;
	position:relative;
}
.tp-caption{
	white-space: normal!important;
	width: inherit;
	@include mobile-all {
		width: 100%;
	}
	@include tablet {
		width: 85%;
	}
	@include desktops {
		width: 70%;
	}
	@include large-desktops {
		width: 65%;
	}
}
.tp-caption.rs-caption-1{
	text-transform: uppercase;
	font-weight: 700;
	@include mobile-all {
		font-size: $base*2+1px !important;
		line-height: $base*3+2px !important;
	}
	@include tablet {
		font-size: $base*4 !important;
		line-height: $base*6 !important;
	}
	@include desktops {
		font-size: $base*6 !important;
		line-height: $base*7 !important;
	}
	@include large-desktops {
		font-size: $base*6 !important;
		line-height: $base*7 !important;
	}
	span{
		display: block;
		@include mobile-all {
			font-size: $base-xs*10 !important;
			line-height: $base-xs*15 !important;
		}
		@include tablet {
			font-size: $base-xs*25 !important;
			line-height: $base-xs*20 !important;
		}
		@include desktops {
			font-size: $base-xs*32 !important;
			line-height: $base-xs*27 !important;
		}
		@include large-desktops {
			font-size: $base-xs*32 !important;
			line-height: $base-xs*27 !important;
		}
	}
}
.tp-caption.rs-caption-2{
	font-weight: 400;
	display: none;
	@include tablet {
		display: block;
		font-size: $base*2+1px !important;
		line-height: $base*3+2px !important;
	}
	@include large-desktops {
		font-size: $base-xs*7 !important;
		line-height: $base*4+1px !important;
	}
}
.rs-caption-3{
	.btn{
		width: 131px;
	}
}
.fullscreenbanner .tp-dottedoverlay.twoxtwo {
  background: rgba(0, 0, 0, 0.15);
  background-image: none!important;
  z-index: 1;
}

.tparrows.preview4 {	
	width:85px; 
	height:85px;  
}
.tparrows.preview4:after{	
	line-height: 85px;
	width:85px;
	height:85px;
	background-color: rgba($white-color, .1); 
}

/*  HOME CITY SLIER */
#rev_slider_wrapper2{
	#rev_slider_container{
		.slide-layer-1{
			font-weight: 700;
			line-height: 55px;
			color: #fff;
			text-transform: uppercase;
			z-index: 10;

		}
		.slide-layer-2{
			font-size: 20px;
			font-weight: 400;
			line-height: 21px;
			color: #fff;
			z-index: 10;
		}

		.slide-layer-3{
			color: #fff;
			text-transform: uppercase;
			border: 2px solid #fff;
			&:hover{
				background: $primary-color;
				border: 2px solid $primary-color;
				cursor: pointer;
			}
			&:focus{
				text-decoration: none;
			}
		}
		.slide-layer-4{
			color: #fff;
			text-transform: uppercase;
			border: 2px solid #fff;

			&:hover{
				background: $primary-color;
				border: 2px solid $primary-color;
				cursor: pointer;
			}
			&:focus{
				text-decoration: none;
			}
		}
	}
}

// AMAZING HOTEL SECTION
.amazingHtoleSection{
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
	padding: 100px 0;

	@include tablet{
		height: 460px;
	}
	@include desktops{
		height: 386px;
	}
	&:before{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.6);
		content: '';
	}
	.buttonCustomPrimary{
		padding: 0 $base-xs*8;
	}
}


.sectionTitleHomeCity2{
	text-align: center;
	margin-bottom: 50px;
	h2{
		font-size: 26px;
		font-weight: 700;
		color: $white-color;
		span{
			color: $primary-color;
		}
	}
	p{
		font-size: 18px;
		color: $white-color;
		line-height: 26px;
	}
}

/* HOME DESTINATION SLIDER */

#rev_slider_wrapper3{
	#rev_slider_container{

		.slide-layer-2{
			text-transform: uppercase;
		}
		.slide-layer-3{
			.container-container-custom{
				width: 530px !important;
				margin-left: -15px;
				margin-right: -15px;
			}
		}
		.tp-rightarrow{
			display: none;
		}
		.tp-leftarrow{
			display: none;
		}
		.tp-bullet{
			display: none;
		}
	}
}
.bannercontainer{
	.rev_slider_wrapper{
		.fullscreenbanner{
			position: relative;
			.tp-dottedoverlay.twoxtwo {
				background: rgba(0, 0, 0, 0.15);
				background-image: none!important;
				z-index: 1;
			}
			font-family: 'Montserrat', sans-serif;

			&:hover{
				.tp-rightarrow.tparrows,
				.tp-leftarrow.tparrows {
					display: block;
				}
			}

			.tp-rightarrow{
				&.tparrows{
					left: 101.5% !important;
					display: none;
					border-radius: 0;
					@include tablet{
						width: 60px;
						height: 60px;
					}
					@include desktops{
						width: 85px;
						height: 85px;
					}
					background-color: rgba(255, 255, 255, 0.1);
					&:hover{
						background-color: rgba(255, 255, 255, 0.1);
					}
					&:before{
						content: '\e825';
						@include tablet{
							line-height: 60px;
						}
						@include desktops{
							line-height: 85px;
						}
					}
				}
			}

			.tp-leftarrow{
				&.tparrows{
					display: none;
					left: -20px !important;
					border-radius: 0;
					@include tablet{
						width: 60px;
						height: 60px;

					}
					@include desktops{
						width: 85px;
						height: 85px;
					}
					background-color: rgba(255, 255, 255, 0.1);
					&:hover{
						background-color: rgba(255, 255, 255, 0.1);
					}
					&:before{
						content: '\e824';
						@include tablet{
							line-height: 60px;
						}
						@include desktops{
							line-height: 85px;
						}
					}
				}
			}
			.tp-bullets{
				top: 97% !important;
				opacity: 1 !important;
				height: auto !important;
				@include desktops {
					bottom: $base*6 !important;
				}
				.tp-bullet{
					background: rgba(255, 255, 255, 0.5) !important;
					@include border-radius (50%);
					box-shadow: none!important;
					width: 6px !important;
					height: 6px !important;
					border: 2px solid rgba(255, 255, 255, 0.0) !important;
					display: inline-block;
					margin-bottom: 0px !important;
					@include transition (background-color 0.2s, border-color 0.2s);
					float: none !important;
					&.selected{
						box-shadow: none!important;
						background: rgba(255,255,255,0)!important;
						width: 14px!important;
						height: 14px!important;
						border: 2px solid white!important;
						margin-right: 0px!important;
						margin-left: -4px!important;
						margin-bottom: -3px!important;
						margin-top: -3px;
					}
					&.hover{
						box-shadow: none!important;
						background: rgba(255, 255, 255, 0.0) !important;
						width: 14px !important;
						height: 14px !important;
						border: 2px solid rgba(255, 255, 255, 1) !important;
						margin-bottom: -3px!important;
					}
				}
			}

		}
	}
}

/*=== 6.2 PROMOTION ===*/
.promotionWrapper{
	background-image: url("../img/home/promotion/promotion-1.jpg");
	background-attachment: fixed;
	width: 100%;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-position: center;
	color: #ffffff;
	height: $base*80+3px;
	.promotionTable{
		display: table;
		height: $base*80+3px;
		width: 100%;
		.promotionTableInner{
			display: table-cell;
			vertical-align: middle;
			text-align: center;
			.promotionInfo{
				text-align: center;
				span{
					line-height: $base*4+1px;
					color: $white-color;
					text-transform: uppercase;
					font-size: $base-xs*9;
				}
				h2{
					font-size: $base-xs*13;
					line-height: $base-xs*16+1px;
					color: $white-color;
					margin-bottom: $base*8;
					margin: $base*2 0 $base*8 0;
				}
				.rating{
					li{
						color: darken(desaturate(adjust-hue($primary-color, 18.4232), 0.7843), 5.8824);
						padding: 0;
					}
				}
				p{
					font-size: $base-xs*9;
					line-height: $base*4+1px;
					margin: 0 0 $base*4 0;
					color: $white-color;
				}
				.buttonCustomPrimary{
					padding: 0 $base-xs*8;
				}
			}
		}
	}
}
/*=== 6.2 PROMOTION 2 ===*/
.promotionWrapperChefsTable{
	background-image: url("../img/home/promotion/promotion-18.jpg");
	background-attachment: fixed;
	width: 100%;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-position: top center;
	color: #ffffff;
	height: $base*60+3px;
	.promotionTable{
		display: table;
		height: $base*60+3px;
		width: 100%;
		.promotionTableInner{
			display: table-cell;
			vertical-align: middle;
			text-align: center;
			.promotionInfo{
				text-align: center;
				span{
					line-height: $base*4+1px;
					color: $white-color;
					text-transform: uppercase;
					font-size: $base-xs*9;
				}
				h2{
					font-size: $base-xs*13;
					line-height: $base-xs*16+1px;
					color: $white-color;
					margin-bottom: $base*8;
					margin: $base*2 0 $base*8 0;
				}
				.rating{
					li{
						color: darken(desaturate(adjust-hue($primary-color, 18.4232), 0.7843), 5.8824);
						padding: 0;
					}
				}
				p{
					font-size: $base-xs*9;
					line-height: $base*4+1px;
					margin: 0 0 $base*4 0;
					color: $white-color;
				}
				.buttonCustomPrimary{
					padding: 0 $base-xs*8;
				}
			}
		}
	}
}

/*=== PROMOTION Seafood International ===*/
.promotionWrapperSeafoodInt{
	background-image: url("../img/home/promotion/promotion-21.jpg");
	background-attachment: fixed;
	width: 100%;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-position: center;
	color: $text-title;
	height: $base*80+3px;
	.promotionTable{
		display: table;
		height: $base*80+3px;
		width: 100%;
		.promotionTableInner{
			display: table-cell;
			vertical-align: middle;
			text-align: center;
			.promotionInfo{
				text-align: center;
				span{
					line-height: $base*4+1px;
					color: $text-title;
					text-transform: uppercase;
					font-size: $base-xs*9;
				}
				h2{
					font-size: 5rem;
					line-height: $base*16+1px;
					color: $text-title;
					margin: $base*3 0 0 0;
				}
				h3{
					font-size: 3rem;
					line-height: $base*16+1px;
					color: $text-title;
				}
				.rating{
					li{
						color: darken(desaturate(adjust-hue($primary-color, 18.4232), 0.7843), 5.8824);
						padding: 0;
					}
				}
				p{
					font-size: 2 rem;
					line-height: $base*4+1px;
					margin: 0 0 $base*4 0;
					color: $text-title;
					font-weight: 600;
				}
				.buttonCustomPrimary{
					padding: 0 $base-xs*8;
				}
			}
		}
	}
}


/*=== 6.3 COUNT UP ===*/
.countUpSection{
	background-image: url("../img/home/promotion/promotion-2.jpg");
	background-attachment: fixed;
	width: 100%;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-position: center center !important;
	color: #ffffff;
	padding: $base*26 0;
	.icon{
		width: $base-xs*48;
		height: $base-xs*48;
		background-color: rgba($primary-color, .9);
		@include border-radius(100%);
		display: inline-block;
		margin-bottom: $base*3;
		i{
			font-size: $base*8+2px;
			line-height: $base-xs*48;
		}
	}
	.counter{
		line-height: $base*8;
		@include mobile-all{
			font-size: $base*6+2px;
		}
		@include tablet{
			font-size: $base*10+2px;
		}
	}
	h5{
		line-height: $base*4+1px;
		text-transform: uppercase;
		@include mobile-all{
			font-size: $base-xs*6+1px;
		}
		@include tablet{
			font-size: $base*3;
		}
	}
}


// FOOD ADN DRINK SECTION
.foodDrinkSection{
	.foodLeftImg{
		display: none;
		@include tablet{
			display: block;
			overflow: hidden;
		}

		.foodImage{
			height: 100%;

		}
	}

	.foodDrinkContent{
		padding: 95px 30px 80px 30px;
		@include tablet{
			padding: 95px 0 72px 30px;
		}
		.foodDrinkHeader{
			margin-bottom: 35px;
			h2{
				font-size: 26px;
				font-weight: 679px;
				color: $text-title;
				span{
					color: $primary-color;
				}
			}
		}
		.FoodDrinkList{
			.item-list{
				border-bottom: 1px solid #dedede;
				padding-bottom: 15px;
				margin-bottom: 20px;
				h4{
					font-size: 14px;
					margin-bottom: 5px;
					text-transform: uppercase;
				}
				a{
					font-size: 13px;
					color: #969696;
					text-transform: uppercase;
				}
				span{
					margin-top: -18px;
					border: 1px solid #dedede;
					padding: 5px 10px;
					@include border-radius (5px);
					font-size: 12px;
					text-transform: uppercase;
				}
			}

		}
	}
}

/*=== 6.4 PROMOTION ===*/
.promotionWrapperBoat{
	background-image: url("../img/home/promotion/promotion-8.jpg");
	background-attachment: fixed;
	width: 100%;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-position: center;
	color: #ffffff;
	height: $base*80+3px;
	.promotionTable{
		display: table;
		height: $base*80+3px;
		width: 100%;
		.promotionTableInner{
			display: table-cell;
			vertical-align: middle;
			text-align: center;
			.promotionInfo{
				text-align: center;
				span{
					line-height: $base*4+1px;
					color: $white-color;
					text-transform: uppercase;
					font-size: $base-xs*9;
				}
				h2{
					font-size: $base-xs*13;
					line-height: $base-xs*16+1px;
					color: $white-color;
					margin-bottom: $base*8;
					margin: $base*2 0 $base*8 0;
				}
				.rating{
					li{
						color: darken(desaturate(adjust-hue($primary-color, 18.4232), 0.7843), 5.8824);
						padding: 0;
					}
				}
				p{
					font-size: $base-xs*9;
					line-height: $base*4+1px;
					margin: 0 0 $base*4 0;
					color: $white-color;
				}
				.buttonCustomPrimary{
					padding: 0 $base-xs*8;
				}
			}
		}
	}
}