* {
    outline: 0;
    margin: 0;
    padding: 0;
}
body {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    line-height: 21px;
    color: lighten(desaturate(adjust-hue($primary-color, -28.5333), 100.0000), 2.9412);
    font-weight: 400;
    // overflow-x: hidden; 
}
ul{
	margin:0;
	padding:0;
	li{
		list-style-type:none;
	}
}
p{
    font-size: 14px;
    color: lighten(desaturate(adjust-hue($primary-color, -28.5333), 100.0000), 2.9412);
    font-weight: 400;
    line-height: 21px;
}
h1, h2, h3, h4{
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    color: $secondary-color;
    margin-top: 0;
	font-weight: 700;
}
h4{
   font-size: $base-xs*9;
   margin-bottom: 25px;  
}
