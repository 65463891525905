/*=== VARIABLES ===*/


$primary-color:#ff891e;
$secondary-color:#262a2e;
$white-color: #ffffff;
$black-color: #000000;
$text-title: #222222;
$background-color: #EBDABE;
$dark-brown: #B7A893;
$primary-baranof:#00393A;
$secondary-baranof:#859E8C;


$title-border: #e5e5e5;

@function tint($primary-color, $percentage) {
  @return mix(white, $primary-color, $percentage);
}

$base: 5px;
$base-xs: 2px;