/*=== MEDIA QUERY ===*/
@mixin mobile-xs{
  @media(min-width:320px){
    @content;
  }
}
@mixin mobile{
  @media(min-width:480px){
    @content;
  }
}
@mixin mobile-all{
  @media(max-width:767px){
    @content;
  }
}
@mixin tablet{
  @media(min-width:768px){
    @content;
  }
}
@mixin desktops{
  @media(min-width:992px){
    @content;
  }
}
@mixin large-desktops{
  @media(min-width:1200px){
    @content;
  }
}