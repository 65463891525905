@import '../partials/mixins';
@import '../partials/variables';
@import '../partials/media-query';

$primary-color:#859E8C;
$baranof-color:#00393A;
$background-color: #EBDABE;

a, 
.sectionTitleDouble h2 span,
.paginationCenter .pagination li a:hover,
.paginationCenter .pagination li.active a,
.paginationCenter .pagination li.active a:hover, 
.paginationCenter .pagination li.active a:focus,
.accordionsTransparent .panel-heading.active,
.thumbnail.deals .caption .captionTitle:hover,
.thumbnail.deals .caption .detailsInfo h5,
.thumbnail.blogPostItem .caption .detailsInfo h4,
.thumbnail.citiesContent .caption .media .media-body a:hover,
.thumbnail.citiesContent .caption .media .media-body h4,
.blogTitle:hover,
.blogInfo li a:hover,
.footerContent .list-unstyled li a:hover,
.copyRight .list-inline li a:hover,
.copyRightText p a:hover,
.destinations .media-body .list-unstyled li i,
.destinations .media-body .list-unstyled li a:hover,
.destinations .media-body .list-unstyled li a:hover i,
.isotopeSelector .overlay .fancybox-pop .overlayInfo h5 span,
.isotopeSelector:hover h4,
.navbar-nav > li.open a, 
.navbar-nav > li.open a:hover, 
.navbar-nav > li.open a:focus,
.navbar-nav > li > a:hover,
.navbar-nav > li.active a, 
.navbar-nav > li.active a:hover,
.navbar-nav > li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li a:hover,
.navbar-nav > li.dropdown.singleDrop .dropdown-menu li a:hover,
.lightHeader .navbar-nav > li > a:hover,
.darkHeader .navbar-nav > li > a:hover,
.footerContent .list-unstyled li a:hover,
.copyRight .list-inline li a:hover,
.copyRightText p a:hover,
.promotionWrapper .promotionTable .promotionTableInner .promotionInfo .rating li,
.media-heading a:hover,
.bookingDetails,
.bookingDetails .buttonTransparent:hover, 
.bookingDetails .paginationCenter .pagination li:first-child a:hover, 
.paginationCenter .pagination li:first-child .bookingDetails a:hover, 
.bookingDetails .paginationCenter .pagination li:last-child a:hover, 
.paginationCenter .pagination li:last-child .bookingDetails a:hover, 
.bookingDetails .isotopeFilters ul.filter > li a:hover, 
.isotopeFilters ul.filter > li .bookingDetails a:hover,
.singlePackage .packageInfo .dl-horizontal dt,
.singlePackage .packageInfo .dl-horizontal dd span,
.singlePackage .packageInfo p span,
.blogSidebar .panel .panel-body .list-group .list-group-item:hover,
.blogSidebar .panel .panel-body .list-group .list-group-item:hover .badge,
.tabArea .tab-content .media .media-body .media-heading:hover,
.blogSinglePost .well .list-inline li a:hover,
.commentsArea .media .media-body .btn-link,
.commentsArea .media .media-body .btn-link:hover,
.aboutComments .commentsTable .commentsTableInner .commentsInfo h5,
.bookingForm .form .checkbox label a, 
.bookingForm .commentsForm .checkbox label a, 
.bookingForm .modal.bookingModal .modal-dialog .modal-content .modal-body .checkbox label a, 
.modal.bookingModal .modal-dialog .modal-content .bookingForm .modal-body .checkbox label a,
.infoArea h2 span,
.priceTotal h2 span,
.priceTable .panel .priceCircle h3,
.priceTable .panel:hover .bodyImageBg .priceCircle h3,
.countryTabs .nav-tabs > li.pull-right .list-inline li a:hover {
  color: $primary-color;
}
.navbar-nav > li > a:hover,
.navbar-nav > li.active a, 
.navbar-nav > li.active a:hover,
.navbar-nav > li.open > a,
.navbar-nav > li.open > a:hover,
.navbar-nav > li.open > a:focus,
.navbar-nav > li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li a:hover,
.navbar-nav > li.dropdown.singleDrop .dropdown-menu li a:hover,
.lightHeader .navbar-nav > li > a:hover,
.darkHeader .navbar-nav > li > a:hover,
.navbar-fixed-top.lightHeader .navbar-nav > li > a:hover {
  color: $primary-color !important;
}
.searchTour .sbOptions a:hover,
.bookingDrop .sbOptions a:hover {
    color: $primary-color !important;
}
.buttonCustomPrimary,
.accordionsTransparent .solidBgTitle .panel-heading,
.accordionsTransparent .accordionSolidTitle .panel-heading.active, 
.accordionsTransparent .accordionSolidBar .panel-heading.active,
.datepicker-dropdown thead tr:last-child th,
.datepicker table tr td.day:hover,
.datepicker table tr td.focused,
.thumbnail.deals .discountInfo .discountOffer,
.priceRange .price-slider-inner .ui-widget-content .ui-slider-range,
.navbar-nav > li > a:after,
.navbar-nav > li.dropdown.searchBox a .searchIcon,
.isotopeSelector .overlay.galleryMasonry .fancybox-pop .overlayInfo .buttonTransparent:hover, 
.isotopeSelector .overlay.galleryMasonry .fancybox-pop .overlayInfo .paginationCenter .pagination li:first-child a:hover, 
.paginationCenter .pagination li:first-child .isotopeSelector .overlay.galleryMasonry .fancybox-pop .overlayInfo a:hover, 
.isotopeSelector .overlay.galleryMasonry .fancybox-pop .overlayInfo .paginationCenter .pagination li:last-child a:hover, 
.paginationCenter .pagination li:last-child .isotopeSelector .overlay.galleryMasonry .fancybox-pop .overlayInfo a:hover, 
.isotopeSelector .overlay.galleryMasonry .fancybox-pop .overlayInfo .isotopeFilters ul.filter > li a:hover, 
.isotopeFilters ul.filter > li .isotopeSelector .overlay.galleryMasonry .fancybox-pop .overlayInfo a:hover,
.navbar-nav > li > a:after,
.navbar-nav > li.dropdown.searchBox a .searchIcon,
.countryTabs .nav-tabs > li > a:after,
.blogSidebar .panel .panel-body .input-group .input-group-addon,
.contactInfo .media .media-left,
.teamMember .maskingArea .list-inline li a:hover,
.progress-wizard > .progress-wizard-step > .progress > .progress-bar,
.progress-wizard > .progress-wizard-step.complete > .progress-wizard-dot,
.comingContent .list-inline li a:hover,
.priceTable .panel:hover .priceCircle,
.tabsPart .nav-tabs li a:hover,
.bookingDetails {
  background-color: $primary-color;
}

.buttonTransparent:hover, 
.paginationCenter .pagination li:first-child a:hover, 
.paginationCenter .pagination li:last-child a:hover, 
.isotopeFilters ul.filter > li a:hover,
.commentsForm .buttonCustomPrimary:hover,
.buttonArea.galleryBtnArea .buttonTransparent:hover, 
.buttonArea.galleryBtnArea .paginationCenter .pagination li:first-child a:hover, 
.paginationCenter .pagination li:first-child .buttonArea.galleryBtnArea a:hover, 
.buttonArea.galleryBtnArea .paginationCenter .pagination li:last-child a:hover, 
.paginationCenter .pagination li:last-child .buttonArea.galleryBtnArea a:hover, 
.buttonArea.galleryBtnArea .isotopeFilters ul.filter > li a:hover, 
.isotopeFilters ul.filter > li .buttonArea.galleryBtnArea a:hover,
.priceRange .price-slider-inner .ui-widget-content .ui-slider-handle,
.navbar-nav > li.dropdown.searchBox .dropdown-menu li .input-group-addon,
.footerContent .input-group .input-group-addon:hover,
.footerContent .list-inline li a:hover,
.isotopeFilters ul.filter > li.active a,
.navbar-nav > li.dropdown.searchBox .dropdown-menu li .input-group-addon,
.footerContent .input-group .input-group-addon:hover,
.footerContent .list-inline li a:hover,
.notFoundContent .input-group .input-group-addon,
.comingContent .input-group .input-group-addon,
.tabsPart .nav-tabs li.active a {
  background-color: $primary-color;
  border-color: $primary-color;
}

.modal.bookingModal .modal-dialog .modal-content, 
.packagesFilter,
.lightHeader .navbar-nav > li > a:hover,
.darkHeader .navbar-nav > li > a:hover,
.lightHeader .navbar-nav > li > a:hover,
.darkHeader .navbar-nav > li > a:hover,
.countryTabs .nav-tabs > li.active > a,
.countryTabs .nav-tabs > li.active > a:focus,
.countryTabs .nav-tabs > li.active > a:hover,
.progress-wizard > .progress-wizard-step > .progress-wizard-dot,
.bookingForm .form .checkbox input[type=checkbox], 
.bookingForm .commentsForm .checkbox input[type=checkbox], 
.bookingForm .modal.bookingModal .modal-dialog .modal-content .modal-body .checkbox input[type=checkbox], 
.modal.bookingModal .modal-dialog .modal-content .bookingForm .modal-body .checkbox input[type=checkbox]{
	border-color: $primary-color;
}
.navbar-fixed-top.lightHeader .navbar-nav > li > a:hover,
.teamMember .maskingArea .list-inline li a:hover {
    border-color: $primary-color;
}
.countUpSection .icon{
	background-color: rgba($primary-color, .9);
}