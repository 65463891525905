/* ===== HOME DESTINATION =====*/
/* SEARCH YOUR DESTINATION */
.searchDestinationSection {
  position: relative;
  top: -150px; }
@media (min-width: 480px) {
  .searchDestinationSection {
    top: -300px; } }
@media (min-width: 768px) {
  .searchDestinationSection {
    position: absolute;
    top: 130px;
    left: 2%; } }
@media (min-width: 992px) {
  .searchDestinationSection {
    top: 135px;
    left: 7%; } }
@media (min-width: 1200px) {
  .searchDestinationSection {
    top: 185px;
    left: 7%; } }
.searchDestinationSection .headingTitle {
  text-align: center; }
@media (min-width: 768px) {
  .searchDestinationSection .headingTitle {
    text-align: left; } }
.searchDestinationSection .headingTitle h2 {
  color: $secondary-baranof;
  font-size: 19px;
  line-height: 36px;
  font-weight: 700; }
@media (min-width: 768px) {
  .searchDestinationSection .headingTitle h2 {
    font-size: 25px; } }
.searchDestinationSection .headingTitle h2 span {
  display: inline-block;
  line-height: 36px;
  color: #fff;
  font-weight: 400; }
@media (min-width: 992px) {
  .searchDestinationSection .headingTitle h2 span {
    display: block;
    font-size: 32px; } }
.searchDestinationSection .searchDestination {
  border: none;
  background-color: #fff;
  color: #222222 !important;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; }
.searchDestinationSection .searchDestination .nav-tabs {
  border-bottom: 0; }
.searchDestinationSection .searchDestination .nav-tabs li {
  border-width: 1px !important;
  border-color: #dedede;
  border-style: solid;
  border-top: 0px;
  margin: 0px !important;
  float: left;
  width: 25%;
  border-right: 0; }
@media (min-width: 768px) {
  .searchDestinationSection .searchDestination .nav-tabs li {
    border-right: 0px; } }
.searchDestinationSection .searchDestination .nav-tabs li:first-child {
  border-left: 0; }
.searchDestinationSection .searchDestination .nav-tabs li:last-child {
  border-right: 0; }
.searchDestinationSection .searchDestination .nav-tabs li.active {
  border-bottom: 0px; }
.searchDestinationSection .searchDestination .nav-tabs li.active a {
  color: $secondary-baranof;
  position: relative;
  border: 0px solid transparent; }
.searchDestinationSection .searchDestination .nav-tabs li.active a span {
  color: #222222; }
.searchDestinationSection .searchDestination .nav-tabs li.active a::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: $secondary-baranof;
  content: ''; }
.searchDestinationSection .searchDestination .nav-tabs li a {
  text-align: center !important;
  border: none;
  margin: 0 !important;
  color: #969696;
  height: 70px;
  padding: 15px 7px; }
@media (min-width: 768px) {
  .searchDestinationSection .searchDestination .nav-tabs li a {
    padding-top: 10px; } }
@media (min-width: 992px) {
  .searchDestinationSection .searchDestination .nav-tabs li a {
    height: 90px;
    padding-top: 20px; } }
.searchDestinationSection .searchDestination .nav-tabs li a:hover {
  background-color: transparent;
  border: 0px !important; }
.searchDestinationSection .searchDestination .nav-tabs li a i {
  display: block;
  font-size: 20px; }
@media (min-width: 768px) {
  .searchDestinationSection .searchDestination .nav-tabs li a i {
    font-size: 25px; } }
@media (min-width: 992px) {
  .searchDestinationSection .searchDestination .nav-tabs li a i {
    font-size: 30px; } }
.searchDestinationSection .searchDestination .nav-tabs li a span {
  text-transform: uppercase;
  font-size: 12px !important; }
@media (min-width: 768px) {
  .searchDestinationSection .searchDestination .nav-tabs li a span {
    font-size: 14px !important; } }
@media (min-width: 768px) {
  .searchDestinationSection .searchDestination .tab-content {
    margin: 10px 0 30px 0px; } }
@media (min-width: 992px) {
  .searchDestinationSection .searchDestination .tab-content {
    margin: 30px 0 30px 0px; } }
@media (min-width: 1200px) {
  .searchDestinationSection .searchDestination .tab-content {
    margin: 30px 0 30px 30px; } }
.searchDestinationSection .searchDestination .tab-content .tab-pane {
  padding: 20px 10px; }
@media (min-width: 768px) {
  .searchDestinationSection .searchDestination .tab-content .tab-pane {
    padding: 20px 40px 50px 20px; } }
.searchDestinationSection .searchDestination .tab-content .tab-pane label {
  font-weight: 400;
  color: #222222; }
.searchDestinationSection .searchDestination .tab-content .tab-pane .marginTop {
  margin-top: 0; }
@media (min-width: 992px) {
  .searchDestinationSection .searchDestination .tab-content .tab-pane .marginTop {
    margin-top: 15px; } }
.searchDestinationSection .searchDestination .tab-content .tab-pane .form-control {
  width: 100%;
  height: 45px;
  border-color: #dedede; }
@media (min-width: 768px) {
  .searchDestinationSection .searchDestination .tab-content .tab-pane .form-control {
    width: 150px; } }
@media (min-width: 992px) {
  .searchDestinationSection .searchDestination .tab-content .tab-pane .form-control {
    width: 200px; } }
@media (min-width: 1200px) {
  .searchDestinationSection .searchDestination .tab-content .tab-pane .form-control {
    width: 222px; } }
.searchDestinationSection .searchDestination .tab-content .tab-pane .form-control:active, .searchDestinationSection .searchDestination .tab-content .tab-pane .form-control:focus {
  border-color: #dedede; }
.searchDestinationSection .searchDestination .tab-content .tab-pane .searchHotel .sbHolder {
  border-color: #dedede;
  margin-bottom: 15px;
  width: 100%; }
@media (min-width: 768px) {
  .searchDestinationSection .searchDestination .tab-content .tab-pane .searchHotel .sbHolder {
    width: 150px; } }
@media (min-width: 992px) {
  .searchDestinationSection .searchDestination .tab-content .tab-pane .searchHotel .sbHolder {
    width: 200px; } }
@media (min-width: 1200px) {
  .searchDestinationSection .searchDestination .tab-content .tab-pane .searchHotel .sbHolder {
    width: 220px; } }
.searchDestinationSection .searchDestination .tab-content .tab-pane .searchHotel .sbHolder .sbToggle {
  background-image: url(../plugins/selectbox/images/arrow-down-2.png);
  background-repeat: no-repeat; }
.searchDestinationSection .searchDestination .tab-content .tab-pane .searchHotel .sbHolder .sbSelector {
  color: #969696 !important;
  text-transform: capitalize; }
@media (min-width: 768px) {
  .searchDestinationSection .searchDestination .tab-content .tab-pane .searchHotel .sbHolder .sbSelector {
    margin-left: -10px; } }
@media (min-width: 992px) {
  .searchDestinationSection .searchDestination .tab-content .tab-pane .searchHotel .sbHolder .sbSelector {
    margin-left: 0px; } }
.searchDestinationSection .searchDestination .tab-content .tab-pane .searchHotel .sbHolder .sbOptions {
  border-color: #dedede; }
.searchDestinationSection .searchDestination .tab-content .tab-pane .input-group.date.ed-datepicker.filterDate {
  position: relative;
  border: 0;
  margin-top: 0;
  margin-bottom: 15px;
  display: block; }
.searchDestinationSection .searchDestination .tab-content .tab-pane .input-group.date.ed-datepicker.filterDate .form-control {
  border-style: solid;
  border-width: 1px !important;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  padding: 4px 12px 4px 12px; }
.searchDestinationSection .searchDestination .tab-content .tab-pane .input-group.date.ed-datepicker.filterDate .form-control:active, .searchDestinationSection .searchDestination .tab-content .tab-pane .input-group.date.ed-datepicker.filterDate .form-control:focus {
  border-color: #969696; }
.searchDestinationSection .searchDestination .tab-content .tab-pane .input-group.date.ed-datepicker.filterDate .input-group-addon {
  position: absolute;
  top: 10px;
  right: 31px; }
@media (min-width: 768px) {
  .searchDestinationSection .searchDestination .tab-content .tab-pane .input-group.date.ed-datepicker.filterDate .input-group-addon {
    right: 10px; } }
@media (min-width: 1200px) {
  .searchDestinationSection .searchDestination .tab-content .tab-pane .input-group.date.ed-datepicker.filterDate .input-group-addon {
    right: 31px; } }
@media (min-width: 992px) {
  .searchDestinationSection .searchDestination .tab-content .tab-pane .searchbtn {
    margin-top: 20px; } }
.searchDestinationSection .searchDestination .tab-content .tab-pane .searchbtn .btn-default {
  background-color: $secondary-baranof;
  padding: 14px 27px;
  color: #fff; }

/*OUR PACKAGES SECTION */
.ourPackagesSection {
  background-image: url("../img/background-4.jpg");
  width: 100%;
  height: auto;
  background-color: $background-color;
  padding-top: 0;
  margin-top: -80px; }
@media (min-width: 480px) {
  .ourPackagesSection {
    margin-top: -200px; } }
@media (min-width: 768px) {
  .ourPackagesSection {
    padding-top: 100px;
    margin-top: 0px; } }
.ourPackagesSection .ourPackage {
  overflow: hidden;
  position: relative;
  cursor: pointer;
  margin-bottom: 30px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; }
.ourPackagesSection .ourPackage:hover .ourPackageContent {
  bottom: 30px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; }
.ourPackagesSection .ourPackage:hover .ourPackageContent .buttonCustomPrimary {
  visibility: visible;
  opacity: 1; }
.ourPackagesSection .ourPackage:hover .ourPackageImg {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; }
.ourPackagesSection .ourPackage:hover .ourPackageImg:after {
  opacity: 1; }
.ourPackagesSection .ourPackage .ourPackageImg {
  height: auto;
  position: relative; }
.ourPackagesSection .ourPackage .ourPackageImg img {
  width: 100%;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; }
.ourPackagesSection .ourPackage .ourPackageImg:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; }
.ourPackagesSection .ourPackage .ourPackageContent {
  position: absolute;
  left: 30px;
  bottom: -30px;
  transition: all 0.4s ease; }
.ourPackagesSection .ourPackage .ourPackageContent h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px; }
.ourPackagesSection .ourPackage .ourPackageContent p {
  color: #fff;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 30px;
  text-transform: uppercase; }
.ourPackagesSection .ourPackage .ourPackageContent p span {
  color: $secondary-baranof; }
.ourPackagesSection .ourPackage .buttonCustomPrimary {
  visibility: hidden;
  opacity: 0;
  height: 35px;
  line-height: 35px;
  padding: 0 10px;
  font-size: 13px; }

.nextTravelSection {
  position: relative;
  background-position: center;
  background-size: cover;
  overflow: hidden; }
.nextTravelSection:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  content: ''; }
.nextTravelSection .sectionTitleHomeCity2 {
  text-align: left; }
.nextTravelSection .content .nextTravelContent {
  margin-top: 35px; }
.nextTravelSection .content .nextTravelContent li {
  color: #fff;
  font-size: 14px;
  margin-bottom: 5px; }
.nextTravelSection .content .nextTravelContent li i {
  margin-right: 15px;
  margin-left: 1px; }
.nextTravelSection .content .buttonCustomPrimary {
  width: 140px;
  height: 45px;
  line-height: 45px;
  margin-top: 35px; }

.videoPlayerSection {
  position: relative;
  background-position: center;
  background-size: cover;
  height: 350px; }
@media (min-width: 768px) {
  .videoPlayerSection {
    height: 500px; } }
@media (min-width: 768px) {
  .videoPlayerSection {
    height: 550px; } }
.videoPlayerSection:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  content: ''; }
.videoPlayerSection .videoPlayerIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
.videoPlayerSection .videoPlayerIcon a {
  display: block;
  text-align: center; }
.videoPlayerSection .videoPlayerIcon i {
  width: 110px;
  height: 110px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 5px solid #fff;
  font-size: 60px;
  color: #fff;
  line-height: 100px;
  padding-left: 10px; }

.topDestinationSection .destinationContent img {
  width: 100%;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; }
.topDestinationSection .destinationContent h5 {
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  color: #222222;
  margin-top: 30px;
  margin-bottom: 15px; }

.packageSAleSection {
  position: relative;
  background-position: center;
  background-size: cover;
  height: 400px; }
@media (min-width: 768px) {
  .packageSAleSection {
    height: 400px; } }
@media (min-width: 768px) {
  .packageSAleSection {
    height: 400px; } }
.packageSAleSection:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  content: ''; }
.packageSAleSection .sectionTitleHomeCity2 {
  text-align: left; }
.packageSAleSection .buttonSale .buttonCustomPrimary {
  line-height: 45px;
  font-size: 15px;
  padding: 0 15px; }
.packageSAleSection .buttonSale .buttonCustomPrimary:hover {
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff; }
.packageSAleSection .buttonSale .buttonTransparent, .packageSAleSection .buttonSale .paginationCenter .pagination li:first-child a, .paginationCenter .pagination li:first-child .packageSAleSection .buttonSale a, .packageSAleSection .buttonSale .paginationCenter .pagination li:last-child a, .paginationCenter .pagination li:last-child .packageSAleSection .buttonSale a, .packageSAleSection .buttonSale .isotopeFilters ul.filter > li a, .isotopeFilters ul.filter > li .packageSAleSection .buttonSale a {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  font-size: 15px;
  height: auto;
  padding: 4px 20px; }
@media (min-width: 768px) {
  .packageSAleSection .buttonSale .buttonTransparent, .packageSAleSection .buttonSale .paginationCenter .pagination li:first-child a, .paginationCenter .pagination li:first-child .packageSAleSection .buttonSale a, .packageSAleSection .buttonSale .paginationCenter .pagination li:last-child a, .paginationCenter .pagination li:last-child .packageSAleSection .buttonSale a, .packageSAleSection .buttonSale .isotopeFilters ul.filter > li a, .isotopeFilters ul.filter > li .packageSAleSection .buttonSale a {
    margin-left: 20px; } }
.packageSAleSection .buttonSale .buttonTransparent:hover, .packageSAleSection .buttonSale .paginationCenter .pagination li:first-child a:hover, .paginationCenter .pagination li:first-child .packageSAleSection .buttonSale a:hover, .packageSAleSection .buttonSale .paginationCenter .pagination li:last-child a:hover, .paginationCenter .pagination li:last-child .packageSAleSection .buttonSale a:hover, .packageSAleSection .buttonSale .isotopeFilters ul.filter > li a:hover, .isotopeFilters ul.filter > li .packageSAleSection .buttonSale a:hover {
  background-color: $secondary-baranof;
  border: 1px solid $secondary-baranof; }

.travelTipsThumbanail .thumbnail {
  margin-bottom: 50px; }
.travelTipsThumbanail .thumbnail img {
  width: 100%;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px; }
.travelTipsThumbanail .thumbnail .caption {
  padding: 0; }
.travelTipsThumbanail .thumbnail .caption h3 {
  font-size: 16px;
  line-height: 26px;
  color: #222222;
  margin-top: 24px;
  margin-bottom: 10px; }
.travelTipsThumbanail .thumbnail .caption .meta-content a {
  margin-right: 15px;
  color: #969696; }
.travelTipsThumbanail .thumbnail .caption .meta-content a i {
  margin-right: 10px; }
.travelTipsThumbanail .thumbnail .caption p {
  line-height: 24px;
  font-size: 14px;
  margin-top: 18px;
  margin-bottom: 27px; }
.travelTipsThumbanail .thumbnail .caption .readMoreBtn {
  text-transform: uppercase;
  color: #969696; }
.travelTipsThumbanail .thumbnail .caption .readMoreBtn:hover {
  color: $secondary-baranof;
  text-decoration: underline; }
.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px; }

